import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Header from '../../components/header';

export default function HomeIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, current_academic_year : {id : '', name : ''}, arr_schedule : {}})
    
    useEffect(async ()=>{
        // var check_user = await base.checkAuth()
        // set_user_data(check_user.user_data)

        window.location.href = '/talent'
    }, [])


    return(
        <div className='row'>

            <div className='col-12'>
                {/* <Header title={'Homeroom'} user_data={user_data} /> */}
            </div>


        </div>
    )
}