import React from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './navbar';
import HomeIndex from '../home';
import AuthLogin from '../auth/login';
import TalentIndex from '../talent';
import HabitChallengeForm from '../talent/habitChallengeForm';
import CurrencySetting from '../talent/currencySetting';
import LogTransactionIndex from '../talent/logTransaction';
import ProfileIndex from '../profile';
import ForgetPassword from '../auth/forgetPass';
import NotificationIndex from '../notification';

export default class BaseLayout extends Base{

  state = {
    token : '',
  }
  
  async componentDidMount(){
    var token = await localStorage.getItem('token')
    await this.setState({token : token})
    // await localStorage.clear()
  }

  render(){
    const { token } = this.state
    return (
      <>
      <Router>
        <div className='position-relative' id="wrapper" style={{width : '100wh', height : '100vh'}}>
            <div className="ratio-169" style={{width : '100%',  backgroundSize : 'cover', backgroundRepeat : 'no-repeat', backgroundImage : "url(" + this.img_background +")"}} ></div>
            
            <div className='background-left d-none d-lg-block'>
              <img src={this.img_logo_talent} />
            </div>
            <div className='background-right d-none d-lg-block'>
              <img src={this.img_logo_talent} />
            </div>

            <div className='position-absolute w-100 pb-5' style={{top : 0}}>
              {
                token != null &&
                <Navbar />
              }

              <div className='container mt-5 pb-5 mb-5'>
                <Routes>
                    <Route exact path={"/"} element={<HomeIndex />}></Route>
                    <Route exact path={"/talent"} element={<TalentIndex />}></Route>
                    <Route exact path={"/talent/habit-challenge"} element={<HabitChallengeForm />}></Route>
                    <Route exact path={"/talent/currency-setting"} element={<CurrencySetting />}></Route>

                    <Route exact path={"/talent/log-transaction"} element={<LogTransactionIndex />}></Route>

                    <Route exact path={"/profile"} element={<ProfileIndex />}></Route>

                    <Route exact path={"/notification"} element={<NotificationIndex />}></Route>

                    <Route exact path={"/auth/login"} element={<AuthLogin />}></Route>
                    <Route exact path={"/auth/forget-password"} element={<ForgetPassword />}></Route>
                </Routes>
              </div>
            </div>
        </div>
      </Router>
      </>
    );
  }
}