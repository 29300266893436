import React, { useEffect, useState, useRef } from 'react';
import Base from '../../utils/base';


export default function ModalRedeem({confirm, redeemValue, modal_redeem_btn_disable}){
    var base = new Base()

    return(
        <div className="modal fade" id="modalRedeem" tabIndex="-1" aria-labelledby="modalRedeemLabel" aria-hidden="true">
            <div className="modal-dialog p-3">
                <div className="modal-content rounded border-0 shadow-sm" style={{backgroundColor : '#F8F9FE'}}>
                    <div className="modal-body p-0">
                        <div className={'row m-0'}>
                            <div className='col-12 py-3'>
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <h5 className='m-0' style={{color : 'Black', fontFamily : 'InterBold'}}>Redeemed Habit Challenge</h5>
                                    </div>

                                    <div className='col-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className='m-0' style={{color : 'black'}}>You are about to redeem your Children’s Habit. Would you like to proceed</p>
                                            </div>
                                            <div className='col-12 mt-3'>
                                                <label className='text-primary labelInput'>Total Talent Achieve</label>
                                                <div className='input-group input-group rounded bg-white' style={{border : '1px solid #EAEAEA'}}>
                                                    <input type='text' className="form-control rounded border-0 bg-transparent" value={redeemValue} readOnly />
                                                    <div className="input-group-append px-3" style={{borderLeft : '1px solid'}}>
                                                        <span className="input-group-text border-0 bg-transparent text-primary">Talent</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-12 mt-4'>
                                        <div className='row m-0'>
                                            <div className='col p-0 pr-1 pr-lg-2'>
                                                <button className='btn btn-outline-primary rounded shadow-sm px-5 w-100' data-dismiss="modal" disabled={modal_redeem_btn_disable}>Cancel</button>
                                            </div>
                                            <div className='col p-0 pl-1 pl-lg-2'>
                                                <button className='btn btn-primary rounded shadow-sm px-5 w-100' onClick={()=>confirm()} disabled={modal_redeem_btn_disable}>Confirm</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}