import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../../components/header';

export default function ProfileIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({name : '', email : '', phone : '', image : {image_display : base.img_no_profile}})

    const [talent_book_arr] = useState([
        {title : 'Accomplish 2 New Habit', value : 100, type : 'plus'},
        {title : 'You have Redeem some of your Talent', value : 50, type : 'min'},
    ])

    const [profile_menu_arr] = useState([
        {title : 'Grade Book', desc : 'View all of your grade', icon : 'fas fa-book', nav : '/profile/grade-book'},
        {title : 'Student Profile', desc : 'View & Edit your profile', icon : 'fas fa-user-friends', nav : '/profile/student-profile/edit'},
    ])

    async function logout(){
        var firebaseToken = await localStorage.getItem('firebaseToken')

        var response = await base.request('/auth/logout', 'post', {token : firebaseToken})
        if(response != null){
            if(response.status == 'success'){
                localStorage.clear()
                window.location.href = '/auth/login'
            }
        }
    }

    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={'profile'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-4'>
                <div className='row'>

                    <div className='col-12 mr-0 mr-lg-4'>
                        <div className="card rounded shadow-sm">
                            <div className={"card-body p-0"}>
                                <div className={'row m-0'}>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className='col-12 mt-5'>
                <div className='row m-0'>
                    <div className='col-12 col-lg-7 bg-white p-4 px-4 rounded shadow'>
                        <div className='row m-0'>
                            <div className='col-12 col-lg-auto px-4' style={{cursor : 'pointer'}} onClick={logout}>
                                <div className='row'>
                                    <div className='col-auto p-0'>
                                        <div className='d-flex align-items-center justify-content-center' style={{height : '2.25rem', width : '2.25rem', backgroundColor : '#ED757C', borderRadius : '.5rem'}}>
                                            <h4 className='m-0'><i class="bi bi-power text-white"></i></h4>
                                        </div>
                                    </div>
                                    <div className='col-auto d-flex align-items-center justify-content-center'>
                                        <p className='m-0'><b style={{color : 'black'}}>Loging Out</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg d-flex align-items-center justify-content-center justify-content-lg-end mt-3 mt-lg-0'>
                                <p className='m-0' style={{fontSize : '.75rem'}}>Version Application 1.0.1</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}