import { useEffect, useState } from 'react';
import Base from '../utils/base';


export default function Header({title, user_data}){
    var base = new Base()

    const [greeting, set_greeting] = useState('')
    const [is_student_access, set_is_student_access] = useState(false)

    useEffect(async ()=>{
        var currentTime = base.moment().format('HH')
        if (currentTime >= 5 && currentTime < 12){
            set_greeting("Good Morning")
        } else if (currentTime >= 12 && currentTime < 15){
            set_greeting("Good Afternoon")
        }   else if (currentTime >= 15 && currentTime < 20){
            set_greeting("Good Evening")
        } else if (currentTime >= 20 && currentTime < 5){
            set_greeting("Good Night")
        }
    }, [])

    return(
        <>
            <div className='row'>
                <div className='col-auto d-flex align-items-center'>
                    <img className='headerProfileImg' src={user_data.image.image_display} />
                </div>
                <div className='col d-flex align-items-center p-0 p-lg-3'>
                    <div className='headerSection'>
                        <h5 className='m-0 text-primary text-uppercase'>{greeting}</h5>
                        <h2 className='m-0 mt-1 text-capitalize headerName' style={{fontFamily : 'PlayfairDisplayBold'}}>{user_data.name}</h2>
                        <p className='m-0 mt-1 text-uppercase'>{base.moment().format('dddd, DD MMMM YYYY')}</p>
                    </div>
                </div>
            </div>
        </>
    )
}