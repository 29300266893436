import React, { useEffect, useState, useRef } from 'react';
import Base from '../../../utils/base';


export default function ModalAmount({amount, changeInput, submitRequest, modal_view_type, rate, convert, is_alert, alert_data}){
    var base = new Base()

    return(
        <div className="modal fade" id="modalAmount" tabIndex="-1" aria-labelledby="modalAmountLabel" aria-hidden="true">
            <div className="modal-dialog p-3">
                <div className="modal-content rounded border-0 shadow-sm">
                    <div className="modal-body p-0">
                        <div className="card rounded shadow-sm h-100 w-100">
                            <div className="card-body p-0">
                                <div className='row m-0'>
                                    <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                                    <div className='col-12 py-3 pb-4'>
                                        <form className='row'>
                                            <div className='col-12'>
                                                <h5 className='m-0' style={{color : 'black', fontFamily : 'InterBold'}}>{modal_view_type === 'input' ? 'Check Out Talent' : 'Confirming Check Out'}</h5>
                                            </div>

                                            {
                                                modal_view_type === 'input' ?
                                                <>
                                                    <div className='col-12 mt-4'>
                                                        <label className='text-primary labelInput'>Amount of Talent</label>
                                                        <div className='input-group input-group rounded bg-white' style={{border : '1px solid #EAEAEA'}}>
                                                            <input type='text' className="form-control rounded border-0 bg-transparent" value={amount} onChange={(e)=>changeInput(e.target.value)}/>
                                                        </div>
                                                        <h5 className='m-0 text-primary mt-3'>= Rp. {parseFloat(rate * amount).toLocaleString(base.currencyFormat)}</h5>
                                                    </div>
                                                    <div className='col-12 mt-4 mt-lg-5'>
                                                        <button type='button' className='btn btn-primary rounded px-5 py-2 w-100' onClick={()=>submitRequest('toConfirm')}>Submit Request</button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {
                                                        is_alert &&
                                                        <div className='col-12 mt-3'>
                                                            <div className={"rounded alert alert-" + (alert_data.type)} role="alert">{alert_data.message}</div>
                                                        </div>
                                                    }
                                                    <div className={'col-12' + (is_alert ? ' mt-0' : ' mt-4')}>
                                                        <p className='m-0'>Are you sure you want to <span style={{fontFamily : 'InterBold'}}>Check Out</span> {amount} Talent = Rp. {convert}</p>
                                                    </div>
                                                    <div className='col-12 mt-4 mt-lg-5'>
                                                        <div className='row m-0'>
                                                            <div className='col p-0 pr-2'>
                                                                <button type='button' className='btn btn-outline-primary rounded shadow-sm px-5 w-100' onClick={()=>submitRequest('back')}>No</button>
                                                            </div>
                                                            <div className='col p-0 pl-2'>
                                                                <button type='button' className='btn btn-primary rounded shadow-sm px-5 w-100' onClick={()=>submitRequest('submit')}>Yes</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}