import React, { useEffect, useState, useRef } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import Header from '../../components/header';
import SelectOption from '../../components/selectOption';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageHeader from '../../components/pageHeader';

export default function HabitChallengeForm(){
    var base = new Base()

    function useQuery(){
		const {search} = useLocation()
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	
	let query = useQuery()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}})
    const [category_arr, set_category_arr] = useState([])
    const [selected_category, set_selected_category] = useState('')
    const [form_data, set_form_data] = useState({name : '', start_date : ''})
    const [is_disable_btn, set_is_disable_btn] = useState(false)

    const [modal_btn_is_disabled, set_modal_btn_is_disabled] = useState(false)

    const [form_alert, set_form_alert] = useState({type : '', message : ''})
    
    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            get_category()

            if(query.get('type') !== 'add'){
                get_data()
            }
        }
    }, [user_data])

    async function get_data(){
        var url = '/habit/tracker?id=' + query.get('id')
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data

                set_selected_category(response_data.category_habit_id)
                set_form_data(response_data)
                // const [selected_category, set_selected_category] = useState([])
                // const [form_data, set_form_data] = useState({name : '', start_date : ''})
			}
		}
    }

    async function get_category(){
        var url = '/habit/category'
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data.data
                set_category_arr(response_data)
			}
		}
    }

    function backBtn(){
        window.location.href = '/talent'
    }

    function changeInput(val, type){
        alert()
        if(type === 'category'){
            set_selected_category(val)
        }
        else{
            base.update_object(form_data, set_form_data, val, type)
        }
    }

    function alert(type='', message=''){
        set_form_alert({type : type, message : message})
    }

    async function saveBtn(){
        var data = form_data
        data.start_date = (form_data.start_date !== '' ? base.moment(form_data.start_date).format('YYYY-MM-DD') : '')
        data.category_habit = {id : selected_category}

        if(data.name === ''){
            alert('danger', 'Habit name cannot be empty')
        }
        else if(data.category_habit.id === ''){
            alert('danger', 'Habit Category cannot be empty')
        }
        else if(data.start_date === ''){
            alert('danger', 'Start date cannot be empty')
        }

        else{
            set_is_disable_btn(true)
            var method = (query.get('type') === 'add' ? 'post' : 'put')
            var url = '/habit/tracker'
    
            if(query.get('type') === 'edit'){
                data.id = query.get('id')
            }
    
            var response = await base.request(url, method, data)
            if(response != null){
                set_is_disable_btn(false)
                if(response.status == 'success'){
                    window.location.replace('/talent')
                }
            }
        }

    }

    async function deleteBtn(type){
        if(type === 'open_modal'){
            base.$('#modalConfirmDelete').modal('show')
        }
        else if(type === 'delete_action'){
            set_is_disable_btn(true)
            var url = '/habit/tracker?id=' + query.get('id')
    
            var response = await base.request(url, 'delete')
            if(response != null){
                set_is_disable_btn(false)
                if(response.status == 'success'){
                    window.location.replace('/talent')
                }
            }
        }
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={'Profile'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <PageHeader backBtn={()=>backBtn()} title={query.get('type') + ' Habit Challenge'} />
            </div>

            <div className='col-12 mt-3 mt-lg-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="card rounded shadow-sm h-100 w-100">
                            <div className="card-body p-0">
                                <div className='row m-0'>
                                    <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                                    <div className='col-12 p-3 pt-4 pb-5'>
                                        <form className='row m-0'>
                                            {
                                                form_alert.message !== '' &&
                                                <div className='col-12 p-0'>
                                                    <div className={"rounded alert alert-" + (form_alert.type)} role="alert">{form_alert.message}</div>
                                                </div>
                                            }
                                            <div className={"col-12 form-group m-0 p-0"}>
                                                <label className='text-primary labelInput'>Name Your Habit</label>
                                                <input type='text' className="form-control form-control-lg rounded editProfileInput" value={form_data.name} onChange={(e)=>changeInput(e.target.value, 'name')}/>
                                            </div>
                                            <div className={"col-12 form-group m-0 mt-3 p-0"}>
                                                <label className='text-primary labelInput'>Habit Category</label>
                                                <SelectOption
                                                    data_arr={category_arr} 
                                                    selected={selected_category}
                                                    title={'Category'}
                                                    changeInput={(value)=>changeInput(value, 'category')}
                                                />
                                            </div>

                                            <div className={"col-12 form-group m-0 mt-3 p-0"}>
                                                <label className='text-primary labelInput'>Start Date</label>
                                                <DatePicker
                                                    selected={form_data.start_date !== '' ? new Date(form_data.start_date) : ''}
                                                    onChange={date => changeInput(date, 'start_date')}
                                                    timeFormat="HH:mm"
                                                    dateFormat="dd MMMM yyyy"
                                                    readOnly={(form_data.status === 'on_progress' ? true : false)}
                                                />
                                            </div>

                                            <div className='col-12 mt-4 mt-lg-5 p-0'>
                                                <button type='button' className='btn btn-primary rounded px-5 py-2 w-100' onClick={saveBtn} disabled={is_disable_btn}>Submit Habit</button>
                                                {
                                                    query.get('type') === 'edit' &&
                                                    <button type='button' className='btn btn-danger rounded px-5 py-2 w-100 mt-3' onClick={()=>deleteBtn('open_modal')} disabled={is_disable_btn}>Delete Habit</button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirmDelete" tabIndex="-1" aria-labelledby="modalConfirmDeleteLabel" aria-hidden="true">
                <div className="modal-dialog p-4 p-lg-0-">
                    <div className="modal-content rounded border-0 shadow-sm">
                        <div className="modal-body rounded p-0" style={{backgroundColor : '#F8F9FE'}}>
                            <div className={'row m-0'}>
                                <div className='col-12 p-4'>
                                    <h5 className='m-0 mt-2 modalConfirmDeleteTitle text-primary' style={{fontFamily : 'InterBold'}}><i className="bi bi-chat-square-dots-fill mr-2 mr-lg-3" style={{color : '#00000066'}}></i>Delete Habit</h5>
                                    <p className='m-0 mt-3' style={{fontSize : '.75rem'}}>Are you sure you would like to delete this habit? </p>

                                    <div className='row'>
                                        <div className='col pr-2'>
                                            <button className='btn btn-outline-primary w-100 rounded shadow-sm mt-4' data-dismiss="modal" disabled={modal_btn_is_disabled}>No</button>
                                        </div>
                                        <div className='col pl-2'>
                                            <button className='btn btn-primary w-100 rounded shadow-sm mt-4' onClick={()=>deleteBtn('delete_action')} disabled={modal_btn_is_disabled}>Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}