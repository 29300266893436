import { useEffect, useState } from 'react';
import Base from '../utils/base';


export default function PageHeader({title, backBtn}){
    var base = new Base()

    useEffect(async ()=>{
    }, [])

    return(
        <>
            <div className='row'>
                <div className='col d-flex align-items-center'>
                    <div className='bg-white shadow-sm rounded d-flex align-items-center justify-content-center backBtn' style={{cursor : 'pointer'}} onClick={()=>backBtn()}>
                        <h2 className='m-0'><i className="bi bi-arrow-left-short" style={{color : '#6F826E'}}></i></h2>
                    </div>
                </div>
                <div className='col-auto d-flex align-items-center justify-content-end'>
                    <div className='shadow-sm rounded bg-white py-2 px-4 text-center'>
                        <p className='m-0 text-center text-capitalize' style={{fontFamily : 'InterBold', color : 'black'}}>{title}</p>
                    </div>
                </div>
            </div>
        </>
    )
}