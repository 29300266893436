import React, { useEffect, useState, useRef } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import Header from '../../components/header';
import PageHeader from '../../components/pageHeader';

export default function CurrencySetting(){
    var base = new Base()

    function useQuery(){
		const {search} = useLocation()
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	
	let query = useQuery()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}})

    const [form_data, set_form_data] = useState({id : '', rate : ''})
    const [is_disable_btn, set_is_disable_btn] = useState(false)
    
    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            get_rate()
        }
    }, [user_data])

    async function get_rate(){
        var url = '/talent/rate'
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data
                var rate = response_data.rate
                response_data.rate = parseFloat(rate).toLocaleString('us-US')
                set_form_data(response_data)
			}
		}
    }

    function backBtn(){
        window.location.replace('/talent')
    }

    function changeInput(val){
        var indexValue = val.length - 1
        var value = ''
        if(val.charCodeAt(indexValue) >= 48 && val.charCodeAt(indexValue) <= 57){
            value = parseFloat(val.replace(/,/g, '')).toLocaleString('us-US')
        }
        else if(indexValue < 0){
            value = ''
        }
        base.update_object(form_data, set_form_data, value, 'rate')
    }

    async function saveBtn(){
        var url = '/talent/rate'
        
        var data = form_data
        data.rate = form_data.rate.replace(/,/g, '')
        
        set_is_disable_btn(true)
		var response = await base.request(url, 'put', data)
		if(response != null){
            if(response.status == 'success'){
                window.location.href = '/talent'
			}
            set_is_disable_btn(false)
		}
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={'Profile'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <PageHeader backBtn={()=>backBtn()} title={'Setting Habit Currency'} />
            </div>

            <div className='col-12 mt-3 mt-lg-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="card rounded shadow-sm h-100 w-100">
                            <div className="card-body p-0">
                                <div className='row m-0'>
                                    <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                                    <div className='col-12 p-3 pt-4 pb-5'>
                                        <form className='row m-0'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-auto d-flex align-items-center'>
                                                        <img src={base.img_tree_talent} style={{height : '7rem'}} />
                                                    </div>
                                                    <div className='col d-flex align-items-center'>
                                                        <h5 className='m-0' style={{fontFamily : 'InterBold', color : 'black'}}>1 Talent</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-12 form-group m-0 p-0 mt-3"}>
                                                <label className='text-primary labelInput'>Currency to Rupiah</label>
                                                <div className='input-group input-group-lg rounded' style={{border : '1px solid #EAEAEA'}}>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text border-0 bg-transparent">Rp. </span>
                                                    </div>
                                                    <input type='text' className="form-control form-control-lg rounded border-0 editProfileInput" value={form_data.rate} onChange={(e)=>changeInput(e.target.value)}/>
                                                </div>
                                            </div>

                                            <div className='col-12 mt-4 mt-lg-5 p-0'>
                                                <button type='button' className='btn btn-primary rounded px-5 py-2 w-100' onClick={saveBtn} disabled={is_disable_btn}>Save Changes</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}