import { useEffect, useState } from 'react';
import Base from '../../../utils/base';

import Header from '../../../components/header';
import ModalAmount from './modalAmount';
import PageHeader from '../../../components/pageHeader';

export default function LogTransactionIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, child : {talent_balance : 0, talent_convert : 0}})
    const [amountCheckout, set_amountCheckout] = useState('')
    const [modal_view_type, set_modal_view_type] = useState('input')

    const [rate_data, set_rate_data] = useState({id : '', rate : 0})
    const [log_arr, set_log_arr] = useState([])
    const [convert, set_convert] = useState('')

    const [is_alert, set_is_alert] = useState(false)
    const [alert_data, set_alert_data] = useState({type : '', message : ''})

    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            get_data()
            get_rate()
        }
    }, [user_data])

    async function get_rate(){
        var url = '/talent/rate'
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data
                set_rate_data(response_data)
			}
		}
    }

    async function get_data(){
        var url = '/talent/transaction'
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data.data
                for(var x in response_data){
                    response_data[x].status = (response_data[x].is_approve ? 'Done' : 'Pending')
                }
                set_log_arr(response_data)
			}
		}
    }

    function openModalCheckout(){
        set_modal_view_type('input')
        set_is_alert(false)
        base.$('#modalAmount').modal('show')
    }

    function changeInput(val){
        var indexValue = val.length - 1
        var value = ''
        if(val.charCodeAt(indexValue) >= 48 && val.charCodeAt(indexValue) <= 57){
            value = parseFloat(val.replace(/,/g, '')).toLocaleString('us-US')
        }
        else if(indexValue < 0){
            value = ''
        }
        set_amountCheckout(value)
    }

    async function submitRequest(type){
        if(type === 'toConfirm'){
            var amount_talent_checkout = amountCheckout.replace(/,/g, '')
            var total_convert = parseFloat(amount_talent_checkout) * parseFloat(rate_data.rate)
            total_convert = parseFloat(total_convert).toLocaleString(base.currencyFormat)
            set_convert(total_convert)
            
            set_modal_view_type('confirm')
        }
        else if(type === 'back'){
            set_is_alert(false)
            set_modal_view_type('input')
        }
        else if(type === 'submit'){
            var url = '/talent/transaction/withdraw'
            var data = {
                amount : amountCheckout
            }
            var response = await base.request(url, 'post', data)
            if(response != null){
                if(response.status == 'success'){
                    set_is_alert(true)
                    change_alert('success', 'Success!!')
                    window.location.reload()
                }
                else{
                    set_is_alert(true)
                    change_alert('danger', response.message)
                }
            }
        }
    }

    function change_alert(type, message){
        set_alert_data({type : type, message : message})
    }

    function backBtn(){
        window.location.replace('/talent')
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={'Profile'} user_data={user_data} />
            </div>

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <PageHeader backBtn={()=>backBtn()} title={'Log Transaction Habit'} />
            </div>

            <div className='col-12 mt-3 mt-lg-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="card rounded shadow-sm h-100 w-100">
                            <div className="card-body p-0">
                                <div className='row m-0'>
                                    <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
                                    <div className='col-12 p-3 py-4'>
                                        <form className='row m-0'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <img src={base.img_tree_talent} style={{height : '7rem'}} />
                                                    </div>
                                                    <div className='col d-flex align-items-center justify-content-center'>
                                                        <div>
                                                            <h5 className='m-0 text-center' style={{fontFamily : 'InterBold', color : 'black'}}>{(user_data.child != null ? user_data.child.talent_balance : '0')} Talent</h5>
                                                            <p className='rounded py-2 px-4 font-weight-bold mt-2' style={{backgroundColor : '#F0F8EF', color : '#60B158'}}>Rp. {parseFloat((user_data.child != null ? user_data.child.talent_convert : '0')).toLocaleString(base.currencyFormat)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mt-4'>
                                                <button type='button' className='btn btn-primary shadow-sm rounded w-100' onClick={()=>openModalCheckout()}>Check Out Talent</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12 mt-4'>
                <div className='row'>

                    {
                        log_arr.map((data, index)=>(
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-auto'>
                                        <p className='m-0'><i className="bi bi-circle-fill text-primary" style={{fontSize : '.625rem'}}></i></p>
                                    </div>
                                    <div className='col'>
                                        <p className='m-0'>{data.description}</p>
                                        <p className={'m-0 ' + (data.type === 'in' ? 'text-secondary' : 'text-danger')} style={{fontSize : '1rem', fontFamily : 'InterBold'}}>{data.amount} talent = {parseFloat(data.amount_convert).toLocaleString(base.currencyFormat)}</p>
                                    </div>
                                    <div className='col'>
                                        <p className='m-0 text-uppercase'>{data.status}</p>
                                    </div>
                                    <div className='col'>
                                        <p className='m-0'>{base.moment(data.created_at).format('DD/MM/YYYY')} Pk. {base.moment(data.created_at).format('HH:mm')}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <ModalAmount
                amount={amountCheckout}
                changeInput={(val)=>changeInput(val)}
                submitRequest={(type)=>submitRequest(type)}
                modal_view_type={modal_view_type}
                rate={rate_data.rate}
                convert={convert}
                is_alert={is_alert}
                alert_data={alert_data}
            />

        </div>
    )
}