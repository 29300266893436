import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import Header from '../../components/header';
import ModalRedeem from './modalRedeem';

export default function TalentIndex(){
    var base = new Base()

    const [user_data, set_user_data] = useState({id : '', name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, child : {talent_balance : 0, talent_convert : 0, total_ongoing_habit_tracker : 0}})

    const [menu_arr] = useState([
        {icon : 'bi bi-journal-bookmark-fill', title : 'Log Habit', nav : '/talent/log-transaction'},
        {icon : 'bi bi-gear-fill', title : 'Setting Currency', nav : '/talent/currency-setting'},
        {icon : 'bi bi-plus', title : 'Add Habit Challenge', nav : '/talent/habit-challenge?type=add'},
    ])

    const [nav_button_arr] = useState([
        {icon : 'fas fa-chevron-circle-left', type : 'prev', margin : 'mr-2'},
        {icon : 'fas fa-chevron-circle-right', type : 'next', margin : 'ml-2'},
    ])

    const [history_counter, set_history_counter] = useState(0)
    const [data_tracker_arr, set_data_tracker_arr] = useState([])
    const [date_arr, set_date_arr] = useState([])
    const [redeemValue, set_redeemValue] = useState('')

    const [tracker_selected, set_tracker_selected] = useState(null)
    const [selected_date, set_selected_date] = useState('')

    const [selected_tracker, set_selected_tracker] = useState(null)
    const [modal_complete_btn_disable, set_modal_complete_btn_disable] = useState(false)
    const [index_tracker_selected, set_index_tracker_selected] = useState(null)
    const [index_date_selected, set_index_date_selected] = useState(null)
    const [is_date_selected, set_is_date_selected] = useState(false)
    
    useEffect(async ()=>{
        var check_user = await base.checkAuth()
        set_user_data(check_user.user_data)

        base.$('#modalCompleteHabit').on('hidden.bs.modal', function (event) {
            set_index_tracker_selected(null)
            set_index_date_selected(null)
        })
    }, [])

    useEffect(async ()=>{
        if(user_data.id !== ''){
            get_data()
        }
    }, [user_data, history_counter])

    async function get_data(){
        var url = '/habit/tracker?history_counter=' + history_counter + '&type=parent_api'
		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var response_data = response.data.data

                var date_data = response.arr_date
                for(var x in date_data){
                    date_data[x].date = base.moment(date_data[x].id).format('DD')
                    date_data[x].day = base.moment(date_data[x].id).format('dd')[0]
                }
                set_date_arr(date_data)
                set_data_tracker_arr(response_data)
			}
		}
    }

    function openModalRedeem(index){
        set_redeemValue(data_tracker_arr[index].talent_earned)
        set_tracker_selected(data_tracker_arr[index])
        base.$('#modalRedeem').modal('show')
    }

    function date_nav(type){
        if(type === 'prev'){
            set_history_counter(history_counter - 1)
        }
        else if(type === 'next'){
            set_history_counter(history_counter + 1)
        }
    }

    const [modal_redeem_btn_disable, set_modal_redeem_btn_disable] = useState(false)

    async function confirmRedeem(){
        var url = '/habit/tracker/redeem'
        var data = {
            id : tracker_selected.id
        }
        set_modal_redeem_btn_disable(true)
        var response = await base.request(url, 'post', data)
        if(response != null){
            if(response.status == 'success'){
                window.location.reload()
            }
            set_modal_redeem_btn_disable(false)
        }
        else{
            set_modal_redeem_btn_disable(false)
        }
    }

    useEffect(()=>{
        if(index_date_selected != null){
            if(index_tracker_selected != null){
                base.$('#modalCompleteHabit').modal('show')
            }
        }
        else{
            base.$('#modalCompleteHabit').modal('hide')
        }
    }, [index_tracker_selected, index_date_selected])

    async function doneHabit(index, index_tracker){
        if(data_tracker_arr[index_tracker].status === 'on_progress'){
            if(base.moment(date_arr[index].id).isSameOrBefore(base.moment(), 'date')){
                set_selected_tracker(data_tracker_arr[index_tracker])
                set_selected_date(date_arr[index].id)
                set_index_tracker_selected(index_tracker)
                set_index_date_selected(index)
                set_is_date_selected(true)
            }
            else if(base.moment(date_arr[index].id).isAfter(base.moment(), 'date')){
                base.$('#modalCompleteHabit').modal('show')
                set_is_date_selected(false)
            }
        }

        // }
    }

    async function completeHabit(){
        var url = '/habit/history'
        var data_post = {
            habit_tracker : {id : selected_tracker.id},
            date : base.moment(selected_date).format('YYYY-MM-DD')
        }

        var data_tracker_index = data_tracker_arr[index_tracker_selected]
        for(var x in data_tracker_arr){
            for(var y in date_arr){
                if(data_tracker_arr[x].arr_history[date_arr[index_date_selected].id] != null){
                    data_tracker_arr[x].arr_history[date_arr[index_date_selected].id] = true
                }
            }
        }
        
        set_modal_complete_btn_disable(true)
        
        var response = await base.request(url, 'post', data_post)
        if(response != null){
            if(response.status == 'success'){
                base.update_array(data_tracker_arr, set_data_tracker_arr, data_tracker_index, index_tracker_selected)
                set_index_tracker_selected(null)
                set_index_date_selected(null)
                // window.location.reload()
            }
            set_modal_complete_btn_disable(false)
        }
        else{
            set_modal_complete_btn_disable(false)
        }
    }

    return(
        <div className='row'>

            <div className='col-12'>
                <Header title={'Profile'} user_data={user_data} />
            </div>

            {/* <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <div className='row'>
                    <div className='col d-flex align-items-center justify-content-end'>
                        <div className='shadow-sm rounded bg-white py-2 px-4 text-center'>
                            <p className='m-0 text-center' style={{fontFamily : 'InterBold', color : 'black'}}>Talent Menu</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='col-12 mt-5 pt-0 pt-lg-4'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>

                            <div className='col-6 pr-2'>
                                <a href='#!' className='text-decoration-none'>
                                    <div className="card rounded shadow-sm h-100" style={{cursor : 'pointer'}}>
                                        <div className="card-body p-3 p-lg-4 d-flex align-items-center">
                                            <div className='row'>
                                                <div className='col-auto pr-0 d-flex align-items-center'>
                                                    <div className='bg-success rounded d-flex align-items-center justify-content-center' style={{width : '2.5rem', height : '2.5rem'}}>
                                                        <h6 className='m-0' style={{color : '#60B158', fontFamily : 'InterBold'}}>{(user_data.child != null ? user_data.child.total_ongoing_habit_tracker : '0')}</h6>
                                                    </div>
                                                </div>
                                                <div className='col d-flex align-items-center'>
                                                    <div className='profileMenu'>
                                                        <p className='m-0' style={{fontFamily : 'InterBold', fontSize : '.75rem'}}>Ongoing Habit Challenge</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className='col-6 pl-2'>
                                <a href='#!' className='text-decoration-none'>
                                    <div className="card rounded shadow-sm h-100" style={{cursor : 'pointer'}}>
                                        <div className="card-body p-3 p-lg-4 d-flex align-items-center">
                                            <div className='row'>
                                                <div className='col-auto d-flex align-items-center justify-content-center pr-0'>
                                                    <img src={base.img_tree_talent} style={{height : '3rem'}} />
                                                </div>
                                                <div className='col d-flex align-items-center'>
                                                    <div>
                                                        <h5 className='m-0' style={{fontFamily : 'InterBold', fontSize : '.75rem'}}>{(user_data.child != null ? user_data.child.total_ongoing_habit_tracker : '0')} Talent</h5>
                                                        <div className='py-1 px-2 font-weight-bold mt-1' style={{backgroundColor : '#F0F8EF', borderRadius : '.5rem'}}>
                                                            <p className='m-0' style={{color : '#60B158', fontSize : '.6rem'}}>Rp. {parseFloat((user_data.child != null ? user_data.child.talent_convert : '0')).toLocaleString(base.currencyFormat)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>

                    <div className='col-12 mt-3'>
                        <div className='row'>
                            {
                                menu_arr.map((data, index)=>(
                                    <div className={'col-4 mb-3'} key={index}>
                                        <a href={data.nav} className='text-decoration-none'>
                                        <div className="card rounded shadow-sm h-100" style={{cursor : 'pointer'}}>
                                            <div className="card-body p-3 p-lg-4 d-flex align-items-center justify-content-center">
                                                <div className='row'>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                        <div className='d-flex align-items-center justify-content-center profileMenuIcon'>
                                                            <h3 className='m-0'><i className={"text-primary " + (data.icon)}></i></h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 d-flex justify-content-center mt-2'>
                                                        <div className='profileMenu'>
                                                            <p className='m-0 text-center' style={{fontSize : '.75rem', lineHeight : '1.25'}}>{data.title}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12 mt-5'>
                <div className='row'>
                    <div className='col-auto d-flex align-items-center justify-content-center'>
                        <div className='shadow-sm rounded bg-white py-2 px-4 text-center'>
                            <p className='m-0 text-center' style={{fontFamily : 'InterBold', color : 'black'}}>{date_arr.length > 0 && base.moment(date_arr[0].id).format('MMMM')}</p>
                        </div>
                    </div>
                    <div className='col d-flex align-items-center justify-content-end'>
                        <div className='row m-0'>
                            {
                                nav_button_arr.map((data, index)=>(
                                    <div className={'col-auto p-0 ' + data.margin} key={index}>
                                        <h4 className='m-0' style={{cursor : 'pointer'}} onClick={()=>date_nav(data.type)}><i className={data.icon + (data.type === 'add' ? ' text-primary' : ' text-secondary')}></i></h4>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={'col-12 mt-3' + (data_tracker_arr.length === 0 ? ' pb-5 mb-5' : '')}>
                <div className='table-responsive'>
                    <table className="table table-borderless">
                        <thead>
                            <tr style={{borderBottom : '1px solid #999999'}}>
                                {
                                    date_arr.map((data, index)=>(
                                        <th className='text-center border-0' style={{color : '#8A92A6'}} key={index}>
                                            <p className='m-0' style={{color : 'black', fontFamily : 'InterBold'}}>{data.day}</p>
                                            <p className='m-0' style={{color : 'black', fontFamily : 'InterBold'}}>{data.date}</p>
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                        {
                            data_tracker_arr.map((data_tracker, index_tracker)=>(
                                <>
                                    <tr key={index_tracker} className='border-0'>
                                        <td colSpan={7} className='px-0'>
                                            <div className='row m-0'>
                                                <div className='col'>
                                                    <div className='row'>
                                                        <div className='col-auto pr-2 d-flex align-items-center'>
                                                            <i className="bi bi-circle-fill" style={{color : '#FC5A5A', fontSize : '.625rem'}}></i>
                                                        </div>
                                                        <div className='col p-0'>
                                                            <p className='m-0 text-capitalize' style={{fontFamily : 'InterBold', color : 'black', fontSize : '1rem'}}>{data_tracker.name}</p>
                                                            <p className='m-0' style={{color : 'black', fontSize : '1rem'}}>{data_tracker.category_habit.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-auto d-flex justify-content-end'>
                                                    <div className='row m-0'>
                                                        <div className='col-auto px-2'>
                                                            <p className='m-0 text-capitalize' style={{fontFamily : 'InterBold', color : 'black', fontSize : '1rem'}}>{data_tracker.history.length} / 30</p>
                                                        </div>

                                                        {
                                                            data_tracker.status === 'done' ?
                                                            <>
                                                                {
                                                                    data_tracker.request_redeem_at == null &&
                                                                    <div className='col-auto d-flex justify-content-end p-0'>
                                                                        <button type='button' className='btn btn-sm btn-primary rounded shadow-sm py-1' onClick={()=>openModalRedeem(index_tracker)}>Redeem</button>
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                            <div className='col-auto d-flex justify-content-end p-0'>
                                                                <div className='' style={{cursor : 'pointer'}}>
                                                                    <a href={'/talent/habit-challenge?type=edit&id=' + data_tracker.id}><i className="bi bi-pen-fill text-primary"></i></a>
                                                                </div>
                                                            </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            date_arr.map((data, index)=>(
                                                <td className='text-center p-0 py-2' key={index}>
                                                    {
                                                        data_tracker.arr_history[data.id] != null &&
                                                        <>
                                                            {
                                                                !data_tracker.arr_history[data.id] ?
                                                                <h5><i className="far fa-circle" style={{color : '#999999', cursor : (base.moment(data.id).isSameOrBefore(base.moment(), 'date') ? 'pointer' : 'default')}} onClick={()=>doneHabit(index, index_tracker)}></i></h5>
                                                                :
                                                                <h5><i className="fas fa-check-circle text-secondary"></i></h5>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                </>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <ModalRedeem
                confirm={()=>confirmRedeem()}
                redeemValue={redeemValue}
                modal_redeem_btn_disable={modal_redeem_btn_disable}
            />

            <ModalCompleteHabit
                confirmHabit={()=>completeHabit()}
                modal_complete_btn_disable={modal_complete_btn_disable}
                is_date_selected={is_date_selected}
            />
            
        </div>
    )
}

function ModalCompleteHabit({confirmHabit, modal_complete_btn_disable, is_date_selected}){
    var base = new Base()
    
    return(
        <>
            <div className="modal fade" id="modalCompleteHabit" tabIndex="-1" aria-labelledby="modalCompleteHabitLabel" aria-hidden="true">
                <div className="modal-dialog p-4 p-lg-0-">
                    <div className="modal-content rounded border-0 shadow-sm">
                        <div className="modal-body rounded p-0" style={{backgroundColor : '#F8F9FE'}}>
                            <div className={'row m-0'}>
                                <div className='col-12 p-4'>
                                    <h5 className='m-0 mt-2 modalCompleteHabitTitle text-primary' style={{fontFamily : 'InterBold'}}><i className="bi bi-chat-square-dots-fill mr-2 mr-lg-3" style={{color : '#00000066'}}></i>Confirming Habit</h5>
                                    <p className='m-0 mt-3' style={{fontSize : '.75rem'}}>{is_date_selected ? 'Are you sure you would like to complete this habit?' : `Sorry, you're not able to submit tracker until the date`}</p>

                                    <div className='row'>
                                        {
                                            is_date_selected ?
                                            <>
                                                <div className='col pr-2'>
                                                    <button className='btn btn-outline-primary w-100 rounded shadow-sm mt-4' data-dismiss="modal" disabled={modal_complete_btn_disable}>No</button>
                                                </div>
                                                <div className='col pl-2'>
                                                    <button className='btn btn-primary w-100 rounded shadow-sm mt-4' onClick={()=>confirmHabit()} disabled={modal_complete_btn_disable}>Yes</button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='col pr-2'>
                                                    <button className='btn btn-outline-danger w-100 rounded shadow-sm mt-4' data-dismiss="modal">Ok</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}